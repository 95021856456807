<template>
<section id="wrapper">
  <div 
    class="login-register" 
  >
    <div class="login-box card">
      <div class="card-body">
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <b-overlay
            :show="loadingOverlay"
            rounded="sm"
          >
            <form 
              @submit.prevent="handleSubmit(doLogin('/do/login'))" 
              class="form-horizontal form-material" 
              id="loginform"
            > 
              <div class="text-center">
                <img :src="baseUrl+'/images/logo-jasa-raharja.png'" />
                <h3 class="f-600 m-b-5">
                  Jasa Raharja SIMR 
                </h3>
                <p>
                  Please sign-in to your account to continue
                </p>
              </div>
              <div class="form-group ">
                <input 
                  class="form-control" 
                  type="text" 
                  placeholder="Username" 
                  v-model="input.username"
                /> 
                <VValidate 
                  name="Username" 
                  v-model="input.username" 
                  rules="required" 
                />
              </div>
              <div class="form-group">
                <input 
                  class="form-control allow-enter" 
                  type="password"
                  placeholder="Password" 
                  v-model="input.password" 
                /> 
                <VValidate 
                  name="Password" 
                  v-model="input.username" 
                  rules="required" 
                />
              </div>
              <div class="form-group">
                <div class="d-flex no-block align-items-center">
                  <b-form-checkbox
                    id="ck-1"
                    v-model="input.rememberMe"
                    name="ck-1"
                  >
                    Remember me
                  </b-form-checkbox>
                </div>
              </div>
              <div class="form-group">
                <!-- <router-link :to="{name: 'ForgotPassword'}">Forget Password?</router-link> -->
              </div>
              <div class="form-group">
                <label class="control-label mb-1">Captcha</label>
                <div id="container-captcha" class="d-flex align-items-center">
                  <div id="captcha"></div>
                  <div style="cursor: pointer;" @click="createCaptcha"><i class="ti-reload"></i></div>
                </div>
                <input 
                  class="form-control allow-enter" 
                  type="text"
                  v-model="input.captcha" 
                />
              </div>
              <div class="form-group text-center">
                <div class="col-xs-12 p-b-20">
                  <button 
                    type="submit" 
                    class="btn btn-block btn-lg btn-info btn-rounded"
                  >
                    Log In
                  </button>
                </div>
              </div>
              <div class="form-group m-b-0">
                <div class="col-sm-12 text-center"> 
                  {{Config.app.copyright()}} © 
                  {{ Config.app.company }}. 
                  All Rights Reserved 
                </div>
              </div>
            </form>
          </b-overlay>
        </ValidationObserver>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import Gen from '@libs/Gen'
import GlobalVue from '@libs/Global'

export default {
  extends:GlobalVue,
  data(){
    return { 
      type:'login' ,
      code: null
    }
  },
  methods:{
    createCaptcha() {
      this.input.captcha = ""
      //clear the contents of captcha div first 
      document.getElementById('captcha').innerHTML = "";
      var charsArray =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var lengthOtp = 4;
      var captcha = [];
      for (var i = 0; i < lengthOtp; i++) {
        //below code will not allow Repetition of Characters
        var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
        if (captcha.indexOf(charsArray[index]) == -1)
          captcha.push(charsArray[index]);
        else i--;
      }
      var canv = document.createElement("canvas");
      canv.id = "captcha";
      canv.width = 80;
      canv.height = 40;
      var ctx = canv.getContext("2d");
      ctx.font = "25px Georgia";
      ctx.strokeText(captcha.join(""), 0, 30);
      //storing captcha so that can validate you can save it somewhere else according to your specific requirements
      this.code = captcha.join("");
      document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
    },

    doLogin(action){
      if(this.input.captcha !== this.code){
        global.Swal.fire({
          icon: 'error',
          title: "Captcha Tidak Valid!",
        })
        this.input.captcha = ""
        this.createCaptcha()
        return
      }


      this.doSubmit(action, this.input, (_, resp)=>{
        this.loading = false
        if (resp.status != 'success') return;

        if (!resp.data.token) return this.type = "2fa"

        this.$store.commit('updateUserToken',resp.data.token)
        this.$store.commit('updateUser', resp.data.user)

        Gen.setCookie('isAdminLogin', 1)

        if(this.$route.query.continue) {
          return window.location=this.baseUrl+''+this.$route.query.continue
        }
        
        window.location=this.baseUrl+'/dashboard'
      })
    }
  },
  mounted(){
    this.createCaptcha()
  }
}
</script>

<style scoped>
@import "../assets/css/pages/login-register-lock.css";
canvas{
  /*prevent interaction with the canvas*/
  pointer-events:none;
}

</style>